import React, { useContext, useEffect } from "react";
import { Web3Button } from "@web3modal/react";
import { useAccount, useEnsName, useDisconnect } from "wagmi";
import useSound from "use-sound";

import { isMobile } from "web3modal";
import { SERVER_URL } from "../../constants/env";
import { MainContext } from "../context/context";

import "./ConnectWallet.scss";

import { notifySuccess } from "../../utils/functions";
import axios from "axios";
import truncateWallet from "../../auth/utils/truncateWallet";

// Assets
import ConnectFox from "../../assets/media/icons/connectFox.png";
import ClickSound from "../../assets/sound/onClickSound.wav";
import { UserContext } from "../../auth/provider/user";

const ConnectWallet = ({ color = null }) => {
  const [playClickSound] = useSound(ClickSound, { volume: 0.325 });
  const { address, isConnected } = useAccount();
  const { data: ensName } = useEnsName({ address });
  const { disconnect } = useDisconnect();
  const { isSoundEnabled } = useContext(MainContext);
  const [isLoading, setIsLoading] = React.useState(false);

  const loginWithWallet = async (wallet) => {
    console.log("wallet sent on login", wallet);
    setIsLoading(true);

    const endpoint = `${SERVER_URL}api/users/loginwithwallet`;
    // const endpoint = `http://localhost:5000/api/users/loginwithwallet`;
    const userInfo = {
      walletAddress: wallet,
    };
    axios
      .post(endpoint, userInfo)
      .then((response) => {
        // add jwt token to local storage on successful login
        if (response.status === 200) {
          localStorage.setItem("bearToken", response.data.data.jwt_token);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const disconnectAndPlaySound = () => {
    if (isSoundEnabled) playClickSound();
    localStorage.clear();
    disconnect();
    notifySuccess("Successfully logged out from your wallet!");
  };

  const connectAndPlaySound = async () => {
    if (isSoundEnabled) playClickSound();
    if (window.ethereum && window.ethereum.isConnected()) {
      try {
        const accounts = await window.ethereum.request({
          method: "eth_accounts",
        });
        if (accounts.length > 0) {
          loginWithWallet(accounts[0].toLowerCase()); // Set the wallet address in the component state
        }
      } catch (error) {
        console.error("Error retrieving wallet address:", error);
      }
    }
  };

  useEffect(() => {
    if (!isConnected) localStorage.clear();
    const handleAccountsChanged = async (newAccounts) => {
      if (newAccounts.length > 0 && newAccounts[0] !== address) {
        localStorage.clear(); // Clear localStorage when the user changes the account
        try {
          await loginWithWallet(newAccounts[0]); // Call loginWithWallet() to log in again and generate a new bearer token
        } catch (error) {
          console.error("Error logging in again:", error);
        }
      }
    };

    // Subscribe to the 'accountsChanged' event from MetaMask
    window.ethereum?.on("accountsChanged", handleAccountsChanged);

    return () => {
      // Unsubscribe from the 'accountsChanged' event when the component unmounts
      window?.ethereum?.removeListener(
        "accountsChanged",
        handleAccountsChanged
      );
    };
  }, [address, isConnected]);

  return (
    <div className="bg">
      <div className="centerer">
        {isConnected && !isLoading ? (
          <>
            <div className="wallet-logout" onClick={disconnectAndPlaySound}>
              {ensName ? (
                <span style={{ color: color }}>{ensName}</span>
              ) : (
                <span style={{ color: color }}>
                  {truncateWallet(address, 33)}
                </span>
              )}
              <span style={{ color: color }} className="logout-text">
                Logout
              </span>
            </div>
          </>
        ) : isLoading ? (
          <>
            <div className="lds-ring">
              <div />
              <div />
              <div />
              <div />
            </div>
          </>
        ) : (
          <div onClick={connectAndPlaySound} className="connect-button">
            <Web3Button
              style={{
                position: "absolute",
                opacity: 0,
                width: isMobile ? "1rem" : "",
                zIndex: 2,
              }}
            />
            <div className="login-button">
              <div className="connect-button-icon-wrapper">
                <img src={ConnectFox} alt="" />
              </div>
              <div className="line" />
              <div className="connect-button-text">Connect</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ConnectWallet;
